import React from "react"
import { ThemeProvider, CssBaseline } from "@mui/material"
import { theme } from "@constants"
import {
  //   DataProviderContextProvider,
  DemoScriptContextProvider,
  GoogleMapsContextProvider,
  LocalizationContextProvider,
  ModeContextProvider,
} from "@context"
import { Main } from "@components"

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationContextProvider>
        <ModeContextProvider>
          {/* <DataProviderContextProvider> */}
          <DemoScriptContextProvider>
            <GoogleMapsContextProvider>
              <Main />
            </GoogleMapsContextProvider>
          </DemoScriptContextProvider>
          {/* </DataProviderContextProvider> */}
        </ModeContextProvider>
      </LocalizationContextProvider>
    </ThemeProvider>
  )
}
