import React from "react"
import { GridColDef } from "@mui/x-data-grid"
import { Typography, styled } from "@mui/material"

import { ILocale } from "@types"
import { formatTime, parseDDtoDMSString } from "@utils"

export const getColumns = (l: ILocale): GridColDef[] => {
  const {
    mapFilters: { time, magnitude, depth },
    activityTable: { station, latitude, longitude },
    units: { km },
  } = l

  return [
    {
      field: "time",
      renderHeader: () => <SColumnHeader children={time} />,
      flex: 1,
      renderCell: ({ row }) => <SCellText children={formatTime(row.time)} />,
    },
    {
      field: "station",
      renderHeader: () => <SColumnHeader children={station} />,
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => <SCellText children={row.station.name} />,
    },
    {
      field: "magnitude",
      renderHeader: () => <SColumnHeader children={magnitude} />,
      flex: 1,
      renderCell: ({ row }) => <SCellText children={row.magnitude} />,
    },
    {
      field: "coord_lat",
      renderHeader: () => <SColumnHeader children={latitude} />,
      width: 120,
      renderCell: ({ row }): JSX.Element => {
        const [lat] = parseDDtoDMSString(row.coord, l.language)
        return <SCellText children={lat} />
      },
    },
    {
      field: "coord_lng",
      renderHeader: () => <SColumnHeader children={longitude} />,
      width: 120,
      renderCell: ({ row }): JSX.Element => {
        const [, lng] = parseDDtoDMSString(row.coord, l.language)
        return <SCellText children={lng} />
      },
    },
    {
      field: "depth",
      renderHeader: () => <SColumnHeader children={`${depth}, ${km}`} />,
      width: 110,
      renderCell: ({ row }) => <SCellText children={row.depth} />,
    },
  ]
}

const SColumnHeader = styled(Typography)({
  fontSize: "14px",
})

const SCellText = styled(Typography)({
  fontSize: "13px",
})
