import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { GridColDef, GridLocaleText, enUS, ruRU } from "@mui/x-data-grid"

import { useDemoScriptContext, useLocalizationContext } from "@context"
import { getColumns } from "./_columns"
import { EMessageType, ELang, IObserver, IQuake, TSetState } from "@types"

interface IUseActivityTableReturn {
  rows: IQuake[]
  columns: GridColDef[]
  page: number
  setPage: TSetState<number>
  localeText: Partial<GridLocaleText>
  coverTitle: string
}

export function useActivityTable(): IUseActivityTableReturn {
  const { l } = useLocalizationContext()
  const columns = useMemo(() => getColumns(l), [l])

  const { demoScript } = useDemoScriptContext()

  const [rows, setRows] = useState<IQuake[]>([])

  const rowsBuffer = useRef<IQuake[]>(rows)
  const shouldUpdate = useRef(false)

  const [page, setPage] = useState(0)

  const localeText = useMemo(() => {
    const locale = l.language === ELang.EN ? enUS : ruRU
    return locale.components.MuiDataGrid.defaultProps.localeText
  }, [l])

  const coverTitle = l.activityTable.currentActivities

  const updateRowsBuffer = useCallback((data: Partial<IQuake>) => {
    const updated = [...rowsBuffer.current]
    const ids = rowsBuffer.current.map((row) => row.id)

    if (!ids.includes(data.id!)) updated.push(data as IQuake)
    else {
      const targetIndex = updated.findIndex((row) => row.id === data.id!)
      updated[targetIndex] = { ...updated[targetIndex], ...data }
    }

    rowsBuffer.current = updated
  }, [])

  const observers = useMemo(
    () => [
      {
        type: EMessageType.QUAKE,
        cb: (data: Partial<IQuake>): void => {
          updateRowsBuffer(data)
          shouldUpdate.current = true
        },
      },

      {
        type: EMessageType.STOP,
        cb: (): void => {
          shouldUpdate.current = false
          setRows([])
          rowsBuffer.current = []
        },
      },
    ],
    []
  )

  useEffect(() => {
    demoScript.on(observers as IObserver<EMessageType>[])

    const intervalId = setInterval(() => {
      if (!shouldUpdate.current) return

      setRows(rowsBuffer.current)
      shouldUpdate.current = false
    }, 500)

    return (): void => {
      demoScript.off(observers as IObserver<EMessageType>[])
      clearInterval(intervalId)
    }
  }, [])

  return {
    rows,
    columns,
    page,
    setPage,
    localeText,
    coverTitle,
  }
}
