import { DataGrid } from "@mui/x-data-grid"
import { styled } from "@mui/material"

export const SDataGrid = styled(DataGrid)({
  "height": "240px !important",
  "width": "100%",

  "& .MuiDataGrid-columnHeaders": {
    minHeight: "0px !important",
    height: "40px !important",
  },

  "& .MuiDataGrid-virtualScroller": {
    overflow: "hidden",
    marginTop: "40px !important",
    height: "160px !important",
  },

  "& .MuiDataGrid-overlay": {
    backgroundColor: "transparent",
    maxHeight: "50px",
  },

  "& .Mui-resizeTriggers": {
    backgroundColor: "transparent",
    border: "1px solid red",
    maxHeight: "50px",
  },

  "& .MuiDataGrid-footerContainer": {
    "minHeight": "0px !important",
    "height": "40px !important",

    "*": {
      minHeight: "0px !important",
    },
  },

  "& .MuiDataGrid-columnHeader.MuiDataGrid-withBorder": {
    border: "none",
  },
})
