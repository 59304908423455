import React from "react"

import { Cover } from "../cover"
import { NoRowsOverlay } from "./fragments"
import { useActivityTable } from "./_useActivityTable"
import { SDataGrid } from "./_style"

export const ActivityTable: React.FC = () => {
  const { rows, columns, page, setPage, localeText, coverTitle } = useActivityTable()

  return (
    <Cover title={coverTitle}>
      <SDataGrid
        rows={rows}
        columns={columns.map((c) => ({ ...c, sortable: false }))}
        localeText={localeText}
        rowHeight={32}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        pagination
        paginationMode="client"
        onPageChange={(newPage): void => setPage(newPage)}
        page={page}
        pageSize={5}
        rowsPerPageOptions={[5]}
        components={{
          NoRowsOverlay: () => <NoRowsOverlay />,
        }}
      />
    </Cover>
  )
}
